
"use strict";

const isSafari = window.safari !== undefined;
let customPolicy = CONFIGURATION.debugOrTest
  ? { sameSite: "none", secure: true }
  : {};
if (isSafari && CONFIGURATION.debugOrTest) {
  delete customPolicy["secure"];
}
const keyName = "hint.session-token.provider";

module.exports = {
  set: function (value) {
    return Cookies.set(keyName, value, customPolicy);
  },
  load: function () {
    return Cookies.get(keyName);
  },
  remove: function () {
    return Cookies.remove(keyName, customPolicy);
  },
};
