"use strict";

// Frontegg does that except when the user locks the computer or freezes the browser

import token from "./token";

function throttle(func, timeFrame) {
  var lastTime = 0;
  return function () {
    var now = Date.now();
    if (now - lastTime >= timeFrame) {
      func();
      lastTime = now;
    }
  };
}

module.exports = {
  forBrowserFreeze: function (loginCallback) {
    document.addEventListener(
      "mousemove",
      throttle(function () {
        if (token.load() && token.isExpired()) {
          console.log("The session is expired, refreshing manually");
          loginCallback("session expired");
        }
      }, 2000),
    );
  },
}
