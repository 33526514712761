"use strict";

import token from "./session/token";
import keepAlive from "./session/keep-alive";
import loginFailureHandler from "./session/login-failure-handler";
import SessionManager from "session-mgmt";
import whitelabelUrl from "./whitelabel-url";
import redirectAfterLogin from "./session/redirect-after-login";

let loginUrl = CONFIGURATION.providerAuthUrl;
let fronteggApp;
let loginRequested = false;

if (CONFIGURATION.backupAuthEnabled) {
  loginUrl = `${CONFIGURATION.redirectToUri}/login`;
  token.useCookies();
}

const readToken = function () {
  return token.load() || null;
};

const SessionPort = {
  read: readToken,
  add: function (app, clientId) {
    const sessionSDK = initSessionSDK();
    fronteggApp = initFronteggApp(clientId, loginUrl);
    keepAlive.forBrowserFreeze(loginWithRedirect);

    app.ports.sessionAuthorizePort.subscribe(function () {
      subscribeToAuth();
      loginFailureHandler.log(fronteggApp);
    });

    if (app.ports.sessionRefreshSessionPort) {
      app.ports.sessionRefreshSessionPort.subscribe(function () {
        loginWithRedirect("refreshing session");
      });
    }

    if (app.ports.logoutPort) {
      app.ports.logoutPort.subscribe(logout);
    }

    if (app.ports.persistBackupAuthTokenPort) {
      app.ports.persistBackupAuthTokenPort.subscribe(function (accessToken) {
        token.set(accessToken);
        dispatchSessionCreatedEvent(accessToken);
      });
    }

    if (app.ports.refreshBackupAuthTokenPort) {
      app.ports.refreshBackupAuthTokenPort.subscribe(function (accessToken) {
        token.set(accessToken);
        app.ports.onAccessTokenUpdatedPort.send(accessToken);
      });
    }

    app.ports.sessionPersistRedirectPort.subscribe(function (url) {
      redirectAfterLogin.persistUrl(url);
    });

    function subscribeToAuth() {
      sessionSDK.run({
        app: fronteggApp,
        onExpirationWarning: function () {
          app.ports.onLogoutWarningPort.send(null);
        },
        onUnAuthenticated: function () {
          redirectAfterLogin.persistUrl();
          loginWithRedirect("unauthenticated");
        },
        onAccessTokenChange: onAccessTokenChange,
      });
    }

    function onAccessTokenChange(accessToken) {
      loginRequested = false;
      if (CONFIGURATION.backupAuthEnabled) {
        return;
      }

      const isNewToken = !readToken();
      token.set(accessToken);

      if (isNewToken) {
        dispatchSessionCreatedEvent(accessToken);
      } else {
        app.ports.onAccessTokenUpdatedPort.send(readToken());
      }
    }

    function loginWithRedirect(loginFrom) {
      if (loginRequested || CONFIGURATION.backupAuthEnabled) {
        return;
      }
      if (!CONFIGURATION.skipSSOInit) {
        fronteggApp.ready(() => {
          setTimeout(() => {
            loginRequested = false;
          }, 5000);
          console.log("Attempting to login from", loginFrom);
          loginRequested = true;
          fronteggApp.loginWithRedirect();
        });
      } else {
        token.useCookies();
        if (readToken()) {
          app.ports.onAccessTokenUpdatedPort.send(readToken());
        }
      }
    }

    function logout() {
      sessionSDK.logout();
    }

    function dispatchSessionCreatedEvent(accessToken) {
      const event = {
        detail: {
          accessToken: accessToken,
          redirectToURI: redirectAfterLogin.retrieveUrl(),
        },
      };

      app.ports.onAccessTokenCreatedPort.send({
        accessToken: event.detail.accessToken || readToken(),
        redirectToURI: event.detail.redirectToURI,
      });

      redirectAfterLogin.resetUrl();
    }

    function initSessionSDK() {
      return new SessionManager({
        postLogoutRedirectURI: whitelabelUrl.get("/login"),
        loginUrl: loginUrl,
        isDevelopment: CONFIGURATION.debugOrTest,
        logger: true,
        backupAuth: CONFIGURATION.backupAuthEnabled,
        apiUrl: `${CONFIGURATION.apiUrl}/api/`,
        onLogout: function () {
          token.remove();
          app.ports.onSessionLoggedOutPort.send(null);
          redirectAfterLogin.persistUrl();
        },
      });
    }
  },
};

function initFronteggApp(clientId, authUrl) {
  return Frontegg.initialize({
    contextOptions: {
      baseUrl: authUrl,
      clientId: clientId,
      tenantResolver: () => {
        return {
          tenant: whitelabelUrl.setAndRetrieve(),
        };
      },
    },
    authOptions: {
      keepSessionAlive: true,
    },
    hostedLoginBox: true,
  });
}

module.exports = SessionPort;
