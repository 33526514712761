"use strict";

import { jwtDecode } from "jwt-decode";
import cookieToken from "./cookie-token";

let clockOffsetMs = 0;
let token = null;
let useCookies = false;

module.exports = {
  set: function (value) {
    clockOffsetMs = jwtDecode(value).iat * 1000 - Date.now();
    if (useCookies) {
      cookieToken.set(value);
    } else {
      token = value;
    }
  },
  load: function () {
    if (useCookies) {
      return cookieToken.load();
    }
    return token;
  },
  remove: function () {
    // for existing cookies or when cookies are enabled, remove them
    if (useCookies || cookieToken.load()) {
      cookieToken.remove();
    } else {
      token = null;
    }
  },
  isExpired: function () {
    let decoded = jwtDecode(token);
    const refreshInAdvanceMs = 5000;
    if (Date.now() + clockOffsetMs + refreshInAdvanceMs >= decoded.exp * 1000) {
      return true;
    }
    return false;
  },
  useCookies: function () {
    useCookies = true;
  },
};
